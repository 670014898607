
.board-contents {
    .v-card {
        border-bottom: 1px solid #ddd;
        transition: all ease-out 0.2s;
        .v-image {
            border-radius: 16px;
            transition: inherit;
        }
        &__title {
            padding: 24px 0 16px;
            transition: inherit;
            line-height: 1.35;
            word-break: keep-all;
        }
        &:hover {
            border-bottom-color: var(--v-primary-base);
            .v-image {
                box-shadow: 0 0 40px 0 rgba(54, 55, 66, 0.3);
            }
            .v-card__title {
                color: var(--v-primary-base);
            }
        }
    }
}
